'use client';

import { useEffect } from 'react';

export const SmoothScroll = () => {
	useEffect(() => {
		const handleSmoothScroll = (event: any) => {
			// Verifica que el enlace comience con #
			if (
				event.target.tagName === 'A' &&
				event.target.getAttribute('href').startsWith('#')
			) {
				event.preventDefault();

				const targetId = event.target.getAttribute('href').substring(1);
				const target = document.getElementById(targetId);

				if (target) {
					// Obtiene la posición del elemento en relación con la parte superior de la página
					const rect = target.getBoundingClientRect();

					// Ajusta el cálculo considerando el espacio adicional (margin-top) en el elemento de destino
					let offsetTop =
						rect.top +
						window.scrollY -
						(document.querySelector('.header') as any).offsetHeight;

					// Ajusta también para el espacio adicional (margin-top) en el elemento de destino
					const marginTop = window.getComputedStyle(target).marginTop;
					offsetTop -= parseFloat(marginTop);

					// Calcula el desplazamiento para que el elemento esté en la parte superior de la pantalla
					offsetTop -= 20; // Puedes ajustar este valor según necesites

					// Realiza la animación de desplazamiento suave
					scrollToOffset(offsetTop, 1000);
				}
			}
		};

		const scrollToOffset = (offset: any, duration: any) => {
			const start = window.pageYOffset;
			const startTime =
				'now' in window.performance ? performance.now() : new Date().getTime();

			const easeInOutCubic = (start: any, end: any, progress: any) => {
				progress /= 0.5;
				if (progress < 1)
					return ((end - start) / 2) * progress * progress * progress + start;
				progress -= 2;
				return (
					((end - start) / 2) * (progress * progress * progress + 2) + start
				);
			};

			const scroll = () => {
				const currentTime =
					'now' in window.performance
						? performance.now()
						: new Date().getTime();
				const timeElapsed = currentTime - startTime;
				const progress = Math.min(timeElapsed / duration, 1);

				window.scrollTo(0, easeInOutCubic(start, offset, progress));

				if (timeElapsed < duration) {
					requestAnimationFrame(scroll);
				}
			};

			requestAnimationFrame(scroll);
		};

		document.addEventListener('click', handleSmoothScroll);

		return () => {
			document.removeEventListener('click', handleSmoothScroll);
		};
	}, []);

	return null; // Opcional: Puedes retornar un elemento JSX si quieres renderizar algo, o simplemente null.
};
